/** @jsx jsx */
import { jsx, Styled } from 'theme-ui';
import { withPrefix } from 'gatsby';
import Obfuscate from 'react-obfuscate';

import { linkStyles } from '../utils/styles';
import useSiteMetadata from '../hooks/use-site-metadata';

import Layout from '../components/Layout';
import Container from '../components/Container2';

const Privacy = () => {
  const { email } = useSiteMetadata();
  return (
    <Layout
      page="privacy"
      seo={{
        title: 'Privacy Policy',
      }}
    >
      <Container
        sx={{
          h3: { my: '48px' },
          'p + p': { mt: '20px' },
          'ul, ol': {
            my: '32px',
            ml: 9,
            li: { pl: 1 },
            'li + li': { mt: '20px' },
          },
          hr: { my: '48px' },
          ul: { listStyle: 'disc' },
          ol: { listStyle: 'lower-latin' },
        }}
      >
        <Styled.h1 sx={{ mb: '48px' }}>Privacy Policy</Styled.h1>

        <Styled.p>July 2020</Styled.p>

        <Styled.p>
          GENAIZ is an innovation accelerator designed specifically for life
          science organizations; providing insights, ideas and recommendations
          that advance existing projects and identify untapped opportunities.
        </Styled.p>

        <hr sx={{ height: '1px', backgroundColor: 'muted' }} />

        <Styled.p>
          GENAIZ (the "Company", "we", "our" or "us"), values your privacy. In
          this GENAIZ Website Privacy Statement ("Privacy Statement"), GENAIZ
          and its affiliates and subsidiaries ("GENAIZ", "we", "us", "our")
          describes the collection, use, sharing and other processing of
          information and data ("Personal Data") pertaining to individual users
          ("you", "your") of all GENAIZ websites including{' '}
          <a href="/" sx={linkStyles({ inverted: true })}>
            https://www.genaiz.com
          </a>{' '}
          all associated sub-domains (the "Sites") and your rights and choices
          regarding our processing of such Personal Data. We also inform about
          how you can access and exercise your rights and how to update your
          information.
        </Styled.p>

        <Styled.p>
          This privacy statement herein complies with EU General Data Protection
          Regulation 2016/679 of April 27, 2016 (“GDPR”) and the relevant
          national legislation of the EU Member States implementing the GDPR
          (together the “Regulations”).
        </Styled.p>

        <Styled.p>
          If you submit your personal data to GENAIZ (irrespective of the manner
          or form), you agree to the terms of this Policy and to its processing
          in accordance with the Regulations.
        </Styled.p>

        <Styled.h3>1. Processing Activities Covered</Styled.h3>

        <Styled.p>
          This Privacy Policy applies to the collection of Personal Data on the
          Sites and its subsequent use in the context, particularly, of the
          following activities:
        </Styled.p>

        <ul>
          <li>
            your registration of a user account on the Sites (e.g., "GENAIZ demo
            request"),
          </li>
          <li>your signing up for newsletters on the Sites,</li>
          <li>your registration for an event or webinar through the Sites,</li>
          <li>
            your provision of Personal Data to us by entering information in any
            other data entry fields or web form embedded in the Sites (e.g.,
            "Request for Information),
          </li>
          <li>your download of content made available via the Sites,</li>
          <li>
            the collection and analysis of Personal Data regarding your use of
            the Sites, and
          </li>
          <li>
            communications between you and us based on your interaction with the
            Sites.
          </li>
        </ul>

        <Styled.p>
          Links to third-party websites on the Site are provided solely as a
          convenience to you. If you use these links, you will leave the Site.
          GENAIZ has not reviewed all of these third-party websites and does not
          control and is not responsible for any of these websites, their
          content or their privacy policies. Thus, GENAIZ does not endorse or
          make any representations about them, or any information, software or
          other products or materials found there, or any results that may be
          obtained from using them. If you decide to access any of the
          third-party websites linked on this Site, you do this entirely at your
          own risk.
        </Styled.p>

        <Styled.h3>2. What Personal Data Do We Collect?</Styled.h3>

        <Styled.p>
          The Personal Data that we collect from or about you may particularly
          include the following:
        </Styled.p>

        <ul>
          <li>
            If you express an interest in obtaining additional information about
            our services, request customer support, use our "Contact Us" or
            similar features, register for a user account on the Site, sign up
            for a newsletter, an event or a webinar, or download certain
            content, we generally require you to provide us with your contact
            information, such as your name, job title, company name, address,
            phone number, email address, or username and password;
          </li>
          <li>
            If you use and interact with the Site, we automatically collect log
            files and other information about your device and your usage of the
            Site through cookies, web beacons or similar technologies, such as
            IP-addresses or other identifiers.
          </li>
          <li>
            Customer relationship information such as customer requests,
            feedback or other information you provide, such as reporting a
            problem with our website; and
          </li>
          <li>
            Information contained in any communication you send to us, such as
            correspondence data may include
          </li>
        </ul>

        <Styled.p>
          In order to recruit potential job candidates, we may also collect:
        </Styled.p>

        <ol>
          <li>
            resume information, such as your employment history, clients lists,
            academic history and transcripts;
          </li>
          <li>
            cover letter information, such as your skills, personal life
            experience and work experience; and
          </li>
          <li>
            remuneration information, such as previous or expected salary,
            benefits and bonuses.
          </li>
        </ol>

        <Styled.p>
          Your use of the Site and your provision of Personal Data to us in the
          process is purely voluntary on your part. Subject to your sole
          discretion, you can, at any point, cease use of the Site or, where
          applicable, opt out of certain data processing operations while using
          the site.
        </Styled.p>

        <Styled.h3>3. Why do we collect Personal Information</Styled.h3>

        <Styled.p>
          We collect and otherwise process your Personal Information to enable
          us to provide, manage and develop our business and operations,
          including:
        </Styled.p>

        <ol>
          <li>
            operating the site to process your personal data to the extent
            required to operate and administer the Site and provide you with
            content you access and request.
          </li>
          <li>
            to establish, maintain and manage relations with you so that we may
            provide you with the products and services that have been requested
            (for example, we will use your Personal Information to determine
            your suitability for a position);
          </li>
          <li>
            promote security of the site. We process your Personal Data by
            monitoring your use of the Site, verifying accounts, and by
            investigating suspicious activity, in order to promote the safety
            and security of the systems and applications used for the Site.
          </li>
          <li>
            Handling contact and user support requests. If you fill out a
            “Contact Me” web form, request a demo or customer/user support, or
            if you contact us by other means through the Site, we process your
            Personal Data in order to respond to your request and communicate
            with you.
          </li>
          <li>
            sending marketing communications. Subject to applicable legal
            requirements, we may process your Personal Data to send you
            marketing information, product recommendations, information about
            our promotions or events, and other non-transactional communications
            about us and our partners via email, SMS push notifications or other
            means of communication.
          </li>
          <li>
            to inform you of services other than those you have specifically
            requested such as information promoting our other services;
          </li>
          <li>
            to understand your qualifications, needs and preferences, in the
            case of a job applicant; as well as, process and respond to your
            application for current and future career opportunities;
          </li>
          <li>
            to comply with your requests (for example, if you prefer to be
            contacted at a business or residential phone number and advise us of
            your preference, we will use this information for that purpose);
          </li>
          <li>
            to protect us against error, fraud, theft and damage to our property
            and goods, including developing and improving the Site. We may
            process your Personal Data to analyze trends and track your usage of
            the Site in order to further develop and improve the Site and
            provide our users with a more pleasant online experience;
          </li>
          <li>to comply with applicable law or regulatory requirements; and</li>
          <li>as otherwise permitted by law.</li>
        </ol>

        <Styled.h3>4. How do we protect the Personal Information?</Styled.h3>

        <Styled.p>
          The Company is committed to maintaining all reasonable physical,
          technical and procedural safeguards to protect your Personal
          Information against unauthorized access, use, modification and
          disclosure, whether electronically or on paper.
        </Styled.p>

        <Styled.p>
          For example, our electronic files are protected by passwords and are
          accessible only to authorized employees who need it to carry out their
          respective duties. In addition, the information is kept safely in
          filing cabinets and protected against unauthorized access. However, we
          cannot guarantee that our security measures can prevent third parties
          (“hackers”) from obtaining and accessing the Personal Information we
          keep.
        </Styled.p>

        <Styled.p>
          International data transfers: GENAIZ may have website visitors from
          jurisdictions located outside the European Economic Area (EEA).
          Countries located outside the EEA may have data protection laws which
          do not provide the same level of protection for personal data as
          within the EEA. GENAIZ utilizes standard means under the Regulations
          to legitimize data transfers outside the EEA.
        </Styled.p>

        <Styled.h3>5. Who Do We Share Your Personal Data With?</Styled.h3>

        <Styled.p>
          We will not use, disclose, or share your Personal Information except
          as described in this Privacy Policy.
        </Styled.p>

        <Styled.p>
          Further to the foregoing uses, your Personal Information may also be
          shared with the Company’ employees, agents, representatives, and
          selected third-party vendors and service providers in Canada and the
          United States (“US”) who require this information to perform services
          on our behalf in connection with hosting or the operation of our
          Website, or to facilitate tasks such as customer support, or marketing
          and analytics. These parties will be obliged to the Company to use
          reasonable care to keep this information confidential and are
          prohibited from using your Personal Information for any purpose other
          than in connection with the service they provide to us.
        </Styled.p>

        <Styled.p>
          The Company reserves the right to transfer your Personal Information
          to a third party in the event of a transfer of all or substantially
          all of its assets to a third party (including transfers made as part
          of insolvency or bankruptcy proceedings) or as part of a corporate
          reorganization or change in corporate control.
        </Styled.p>

        <Styled.p>
          The Company and our Canadian and American service providers may
          disclose your Personal Information without your consent if compelled
          to do so by a court or body with jurisdiction to compel the production
          of information or by regulatory requirements, to an investigative body
          in the case of a breach of an agreement or contravention of law, or as
          otherwise required or permitted by applicable Canadian or US law. We
          may also disclose Personal Information where necessary for the
          establishment, exercise or defence of legal claims and to investigate
          or prevent actual or suspected loss or harm to persons and property.
        </Styled.p>

        <Styled.h3>
          6. Cookies, Analytics and Similar Tracking Technologies
        </Styled.h3>

        <Styled.p>
          In general, you can visit our Website without telling us who you are
          or submitting any Personal Information. We may collect information
          that does not identify you personally, but is linked to your computer
          or device (“Device Identifiable Information”). We collect Device
          Identifiable Information from you in the normal course of operating
          our Website. When you visit our Website to browse, read or download
          information, we collect the IP (Internet protocol) addresses of all
          visitors to our Website and other related information such as page
          requests, browser type, operating system and average time spent on our
          Website. We may also collect information about your mobile device such
          as the type and model, operating system (e.g. iOS or Android), carrier
          name, mobile browser (e.g. Chrome, Safari), applications using our
          Website, and identifiers assigned to your device, such as its iOS
          Identifier for Advertising (IDFA), Android Advertising ID (AAID), or
          unique device identifier (a number uniquely given to your device by
          your device manufacturer), sometimes referred to as a mobile carrier
          ID.
        </Styled.p>

        <Styled.p>
          Cookies: We do use a technology called “cookies” on our Website. A
          cookie is a tiny element of data that our Website can send to your
          browser, which may then be stored on your hard drive so we can
          recognize you when you return. We use cookies to help us understand
          which areas of our Website are useful and which areas need
          improvement. You can control whether some of the cookies are set via
          the Preferences Manager. You may also be able to restrict the use of
          web beacons and other identifiers through a “privacy” or similar
          section in the tool bar of your browser window. However, if you decide
          not to accept cookies from our Website, you may not be able to take
          full advantage of all of the features of our Website.
        </Styled.p>

        <Styled.h3>7. Changes to This Privacy Statement</Styled.h3>

        <Styled.p>
          We will update this Privacy Statement from time to time by posting an
          updated version on the Site to reflect changes in our practices,
          technology, legal requirements or other factors. If we do, we will
          update the “effective date” at the top of this Privacy Statement.
        </Styled.p>

        <Styled.p>
          We encourage you to periodically review this Privacy Statement to stay
          informed about our collection, processing and sharing of your Personal
          Data.
        </Styled.p>

        <Styled.h3>8. Contact Information</Styled.h3>

        <Styled.p>
          If you have questions regarding this Privacy Statement, our data
          processing operations, or to access, update, object or correct
          inaccuracies, please contact our head office.
        </Styled.p>

        <Styled.p>
          GENAIZ Head Office
          <br />
          50 rue Queen, Suite 102
          <br />
          Montreal, QC, H3C 2N5
          <br />
          Canada
          <br />
          Email: <Obfuscate email={email} sx={linkStyles({ inverted: true })} />
        </Styled.p>
        <Styled.p>
          <b>
            <a
              href={
                withPrefix('/') +
                'downloads/genaiz-privacy-policy-jul-2020-en.pdf'
              }
              sx={linkStyles({ inverted: true })}
            >
              Download (PDF)
            </a>
          </b>
        </Styled.p>
      </Container>
    </Layout>
  );
};

export default Privacy;
